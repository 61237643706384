<script>
import { Pie } from "vue-chartjs";
// import ChartDataLabels from "chartjs-plugin-labels";
import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  extends: Pie,
  props: ["height", "dataset", "totalDonations"],
  data() {
    return {
      chartData: this.dataset,
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          datalabels: {
            formatter: function (value, context) {
              // return context.chart.data.labels[context.dataIndex] + value;\
              // console.log(context, "tttt");
              return (
                context.chart.data.labels[context.dataIndex] +
                "\n (" +
                Math.round((value / context.dataset.totalDonation) * 100) +
                "%) "
              );
            },
            display: "end",
            color: function (context) {
              var index = context.dataIndex;
              var value = context.dataset.data[index];
              return value <= 0
                ? "#ED6D11" // draw negative values in red
                : index % 2
                ? "#0085FF" // else, alternate values in blue and green
                : "#067605";
            },
          },
        },
      },
    };
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
    // console.log(this.totalDonations, "dkdnks");
  },
};
</script>
